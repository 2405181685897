<template>
  <div class="myOperation">
    <!--    <el-radio-group v-model="commitStatus" @change="chooseState">-->
    <!--      <el-radio-button label="2">全部</el-radio-button>-->
    <!--      <el-radio-button label="1">已提交</el-radio-button>-->
    <!--      <el-radio-button label="0">未提交</el-radio-button>-->
    <!--    </el-radio-group>-->

    <div class="p-t-24 m-r-32" style="margin-bottom: 13px;padding-top: 10px;">
      <div class="tab_box">
        <div :class="btnInfo === 0 ? 'on' : ''" class="btn_item" @click="changeTabs(0)">
          全部
        </div>
        <div :class="btnInfo === 1 ? 'on' : ''" class="btn_item" @click="changeTabs(1)">
          已提交
        </div>
        <div :class="btnInfo === 2 ? 'on' : ''" class="btn_item" @click="changeTabs(2)">
          未提交
        </div>
        <div class="sliding_block" :style="`left: ${btnInfo * 100 + btnInfo * 4 + 5}px;`" />
      </div>
    </div>
    <div v-for="(item, index) in studentWorkList" :key="index" class="myOperation_item">
      <div class="myOperation_item_left">
        <div class="myOperation_item_left_img">
          <el-image :src="require('@/assets/img/homeSeventh/zuoye01.png')" />
        </div>
        <div class="myOperation_item_left_title">
          <div class="myOperation_item_left_title_name">
            <div class="myOperation_item_left_title_name_left ellipsis">
              {{ item.workName }}
            </div>
            <span class="myOperation_item_left_title_state" :class="item.commitStatus ? 'submitTrue' : ''">
              {{ item.commitStatus == 1 ? "已提交" : "未提交" }}
            </span>
          </div>
          <p v-if="item.commitTime" class="myOperation_item_left_title_state_submitTime">
            提交时间:{{ item.commitTime }}
          </p>
        </div>
      </div>
      <div class="myOperation_item_right">
        <div v-if="item.checkStatus && item.grade == 3" class="myOperation_item_right_img">
          <el-image :src="require('@/assets/img/homeSeventh/good.png')" />
        </div>
        <div v-else-if="item.checkStatus && item.grade == 2" class="myOperation_item_right_img">
          <el-image :src="require('@/assets/img/homeSeventh/grade2.png')" />
        </div>
        <div v-else-if="item.checkStatus && item.grade == 1" class="myOperation_item_right_img">
          <el-image :src="require('@/assets/img/homeSeventh/grade1.png')" />
        </div>
        <div v-else-if="item.checkStatus && item.grade == 0" class="myOperation_item_right_img">
          <el-image :src="require('@/assets/img/homeSeventh/grade0.png')" />
        </div>
        <span v-if="item.checkStatus == 1" class="myOperation_item_right_state"><i style="margin-right: 4px;"
            class="iconfont el-icon-check" />
          已批改</span>
        <div v-if="item.commitStatus == 1 && item.checkStatus != 1" class="myOperation_item_right_state">
          <img src="@/assets/img/homeSeventh/pigaizhong.png" alt="">
          <span>批改中</span>
        </div>
        <el-button v-if="item.commitStatus == 0" class="myOperation_item_right_btn"
          @click="goDetails(item)">完成作业</el-button>
        <el-button v-else :class="item.commitStatus == 1 ? 'submitTrue' : ''" class="myOperation_item_right_btn"
          @click="goDetails(item)">查看详情</el-button>
      </div>
    </div>
    <el-empty v-if="studentWorkList.length == 0" description="暂无数据" />
  </div>
</template>
<script>
import { getStudentWorkList, submitWork } from '@/api/myOp'
export default {
  components: {},
  props: {},
  data() {
    return {
      studentWorkList: [],
      commitStatus: 2,
      btnInfo: 0
    }
  },
  computed: {},
  watch: {},
  async created() {
    await this.getStudentWorkList()
  },
  mounted() { },
  methods: {
    async getStudentWorkList() {
      try {
        const { rows } = await getStudentWorkList()
        if (rows) {
          this.studentWorkList = rows
        }
      } catch (error) {
        console.log(error)
      }
    },
    async chooseState(val) {
      console.log(val)
      if (val == 2) {
        this.getStudentWorkList()
        console.log(this.studentWorkList)
      } else {
        await this.getStudentWorkList()
        this.studentWorkList = await this.studentWorkList.filter(
          (e) => e.commitStatus == this.commitStatus
        )
        this.$forceUpdate()
      }
    },
    goDetails(item) {
      console.log(item)
      this.$router.push(
        `/partnerPage/myOperationDetails?workId=${item.workId}`
      )
    },
    changeTabs(index) {
      this.btnInfo = index
      const status = index === 0 ? 2 : index === 2 ? 0 : 1
      this.commitStatus = status
      this.chooseState(status)
    }
  }
}
</script>

<style lang="less" scoped>
.myOperation {
  width: 100%;

  // height: 480px;
  // background-color: #333;
  /deep/ .el-radio-button {
    width: 100px;
    height: 38px;
    background: #f5f6f7;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    margin-top: 3px;
  }

  /deep/ .is-active {
    width: 100px !important;
    background: #fff !important;
    border: none !important;
  }

  /deep/ .el-radio-button__inner {
    width: 100px;
    height: 38px;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    box-shadow: none !important;
    background: #f5f6f7 !important;
    color: #8a8a8a;
    border: none !important;
  }

  /deep/ .el-radio-group {
    width: 312px;
    height: 44px;
    background: #f5f6f7;
    border-radius: 10px 10px 10px 10px;
    opacity: 1;
    margin: 20px 0 10px;
  }

  .line {
    width: 100%;
    height: 1px;
    background: #eeeeee;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
  }

  .myOperation_item {
    display: flex;
    justify-content: space-between;
    width: 1039px;
    height: 70px;
    background: #ffffff;
    box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.06);
    border-radius: 6px 15px 6px 6px;
    opacity: 1;
    padding-right: 20px;
    margin-bottom: 10px;

    &:first-child {
      margin-top: 10px !important;
    }

    .myOperation_item_left {
      display: flex;
      // width: 400px;
      margin-left: 10px;
      align-items: center;

      .myOperation_item_left_img {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 8px;
        width: 54px;
        height: 54px;
        // padding: 15px;
        background: #f5f5f5;
        border-radius: 8px 8px 8px 8px;
        opacity: 1;
      }

      .myOperation_item_left_title {
        // width: 310px;
        // margin: 6px 0 9px 18px;
        margin-left: 16px;

        .myOperation_item_left_title_name {
          display: flex;
          align-items: center;
          justify-content: flex-start;

          .myOperation_item_left_title_name_left {
            overflow: hidden;
            max-width: 400px;
            // height: 25px;
            margin-right: 10px;
            font-size: 18px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
          }

          .myOperation_item_left_title_state {
            display: inline-block;
            // width: 45px;
            height: 21px;
            line-height: 21px;
            // padding: 3px;
            font-size: 12px;
            padding: 0 4px;
            font-family: PingFang SC-Regular, PingFang SC;
            background: #ffebf1;
            font-weight: 400;
            color: #fe4473;
            flex-shrink: 0;
          }

          .submitTrue {
            color: #2eb988;
            background: #eaf9f2;
          }
        }

        .myOperation_item_left_title_state_submitTime {
          width: 217px;
          // height: 20px;
          margin-left: 2px;
          margin-top: 9px;
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
        }
      }
    }

    .myOperation_item_right {
      width: 500px;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      // margin: auto;
      .myOperation_item_right_img {
        width: 53px;
        height: 53px;
        margin-right: 250px;
      }

      .myOperation_item_right_state {
        display: flex;
        align-items: center;
        // width: 100px;
        height: 20px;
        justify-content: start;
        margin-right: 21px;
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;

        img {
          width: 11px;
          height: 11px;
        }
      }

      /deep/ .myOperation_item_right_btn {
        // width: 87px;
        // height: 29px;
        // line-height: 29px;
        // padding-left: 16px;
        padding: 4px 15px;
        text-align: center;
        color: #FF5E51;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        border: 1px solid #FF5E51;
      }

      /deep/ .submitTrue {
        color: #999999 !important;
        border: 1px solid #999999 !important;
      }
    }
  }

  .tab_box {
    width: 320px;
    height: 45px;
    border-radius: 6px;
    padding: 4px;
    background-color: #f5f7f9;
    display: flex;
    justify-content: space-around;
    position: relative;

    .btn_item {
      width: 100px;
      height: 40px;
      line-height: 30px;
      border-radius: 6px;
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      color: #5e5e5e;
      position: relative;
      z-index: 1;
      cursor: pointer;
      margin-top: 4px;
      padding-right: 10px;
    }

    .sliding_block {
      width: 90px;
      height: 38px;
      border-radius: 6px;
      background-color: #ffffff;
      position: absolute;
      transition: 200ms;
      //text-align: center;
    }
  }
}
</style>
